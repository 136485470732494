var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "common-dialog",
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        height: "10vh",
        "max-height": "680",
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _vm.pageState != "view"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v(" 确 定 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(
                  " " + _vm._s(_vm.pageState == "add" ? "取 消" : "关 闭") + " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.dialogFormVisible
        ? _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "activitiesForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "广告名称:", prop: "name" } },
                    [
                      _vm.pageState != "view"
                        ? _c("el-input", {
                            staticClass: "width200",
                            attrs: {
                              clearable: "",
                              maxlength: "50",
                              placeholder: "请输入广告名称",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.name",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "位置:", prop: "position_key" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "el-select",
                            {
                              staticClass: "width200",
                              attrs: {
                                clearable: "",
                                placeholder: "请选择",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.form.position_key,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "position_key",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.position_key",
                              },
                            },
                            _vm._l(_vm.selectOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.position_key))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片:", prop: "image_link" } },
                    [
                      _c(
                        "nxp-upload",
                        _vm._b(
                          {
                            ref: "nxpUpload",
                            attrs: {
                              accessories: _vm.activeMainImg.accessories,
                              "page-state": _vm.pageState,
                              "upload-item": _vm.activeMainImg,
                            },
                            on: { getUploadList: _vm.getUploadList },
                          },
                          "nxp-upload",
                          _vm.$attrs,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }