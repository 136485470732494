<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <vab-query-form>
      <div style="margin: 10px 0">
        <el-button
          class="leftBtn"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit('', 'add')"
        >
          创建
        </el-button>
      </div>
    </vab-query-form>
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @fetchData="fetchData"
    />
    <addEdit ref="edit" :select-options="selectOptions" @fetch-data="clickSearch" />
    <!-- 预览 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      size="tiny"
      title="查看大图"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <img alt="" class="imgPreviewDialog" :src="currentImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { queryDataList } from "@/project/eztree/baseApi/BillboardApi";
const selectOptions = [
  { label: "首页竖屏banner", value: "vertical_banner" },
  { label: "首页横屏banner", value: "cross_banner" },
];
const statusOptions = [
  { label: "下架", value: "0" },
  { label: "上架", value: "1" },
];
export default {
  name: "Billboard",
  components: {
    addEdit: () => import("./addEdit"),
  },
  data() {
    return {
      selectOptions,
      statusOptions,
      queryForm: {},
      dialogVisible: false,
      currentImageUrl: "",
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "name",
          label: "名称",
        },
        {
          type: "select",
          showName: false,
          model: "",
          param: "status",
          label: "类型",
          options: statusOptions,
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        { label: "广告位名称", prop: "name" },

        {
          label: "位置",
          render: (h, params) => {
            return h(
              "el-tag",
              {
                attrs: {
                  type:
                    params.row.type == "vertical_banner"
                      ? "danger"
                      : params.row.type == "cross_banner"
                      ? "success"
                      : "",
                  effect: "plain",
                },
              },
              selectOptions.map((ele) => {
                if (ele.value == params.row.type) {
                  return ele.label;
                }
              })
            );
          },
        },
        {
          label: "图片",
          key: "link",
          align: "center",
          render: function (h, params) {
            return h("div", [
              h("img", {
                attrs: {
                  style: "height:100px;",
                  src: params.row.link,
                  alt: "图片走丢了",
                },
              }),
            ]);
          },
        },
        {
          label: "状态",
          prop: "status",
          width: 120,
          render: (h, params) => {
            return h(
              "el-tag",
              {
                attrs: {
                  type:
                    params.row.status == "0"
                      ? "danger"
                      : params.row.status == "1"
                      ? "success"
                      : "",
                  effect: "plain",
                },
              },
              params.row.status == "0" ? "下架" : params.row.status == "1" ? "上架" : ""
            );
          },
        },
        {
          label: "创建时间",
          prop: "created_at",
        },
        {
          label: "修改时间",
          prop: "updated_at",
        },
        {
          label: "操作",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {},
              },
              [
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.dialogVisible = true;
                        this.currentImageUrl = params.row.link;
                      },
                    },
                  },
                  "查看大图"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "changeStatus");
                      },
                    },
                  },
                  params.row.status == "1" ? "下架" : "上架"
                ),

                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "edit");
                      },
                    },
                  },
                  "编辑"
                ),

                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "delete");
                      },
                    },
                  },
                  "删除"
                ),
              ]
            );
          },
        },
      ];
      return columns;
    },
  },
  mounted() {},
  methods: {
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      queryDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
  },
};
</script>
